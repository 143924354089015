import React, { useState } from 'react';
export default function Home() {
  return (
    <div className='bg-black flex justify-center'>
      <div className='w-[80%] flex flex-row text-white h-max items-center p-4'>
        <div className='text w-full md:w-[70%] left-0 md:p-8 items-center justify-center'>
          <p className='text-[20px]'>Hello There,</p>
          <p className='text-[20px] text-orange-600'>I am Md. Rashedul Islam</p>
          <h1 className='text-[45px] text-green-600'>Professional Full Stack Developer</h1>
          <p className='p-2 text-[20px]'>With 2 years of experience</p>
          <div className='flex flex-col md:flex-row space-y-3 md:space-x-3 md:space-y-0 md:w-max text-nowrap justify-center'>
            <button className='border border-gray-400 rounded-md p-2 text-[20px] w-full hover:bg-green-600 transform transition duration-500 hover:scale-110'><a href=''>My Works</a></button>
            <button className='border border-gray-400 rounded-md p-2 text-[20px] w-full hover:bg-orange-600 transform transition duration-500 hover:scale-110'><a href=''>Download My CV</a></button>
          </div>
        </div>
        <div className='hidden md:flex image md:w-[30] justify-center items-center right-0'>
          <img src='/images/bgremoved.png' alt='Error' className='w-full' />
        </div>
      </div>
    </div>
    

  );
}

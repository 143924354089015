import React, { useState, useEffect } from 'react';

export default function ProjectCount() {
    const [year, setYear] = useState(0);
    const [projects, setProjects] = useState(0);
    const [happyclient, setHappyClient] = useState(0);

    const yearExperience = 2.5;
    const completeProject = 10;
    const happyClient = 8;

    const maxTime = 5000; 
    const increaseValue = () => {
        const yearInterval = maxTime / (yearExperience * 10); 
        const projectInterval = maxTime / completeProject;
        const clientInterval = maxTime / happyClient;

        const yearTimer = setInterval(() => {
            setYear(year => {
                if (year < yearExperience) return parseFloat((year + 0.1).toFixed(1));
                else {
                    clearInterval(yearTimer);
                    return year;
                }
            });
        }, yearInterval);

        const projectTimer = setInterval(() => {
            setProjects(projects => {
                if (projects < completeProject) return projects + 1;
                else {
                    clearInterval(projectTimer);
                    return projects;
                }
            });
        }, projectInterval);

        const clientTimer = setInterval(() => {
            setHappyClient(happyclient => {
                if (happyclient < happyClient) return happyclient + 1;
                else {
                    clearInterval(clientTimer);
                    return happyclient;
                }
            });
        }, clientInterval);
    };

    useEffect(() => {
        increaseValue();
    }, []);

    return (
        <div className='bg-[#161616] flex justify-center p-4'>
            <div className='project-counts w-[80%] text-[22px] flex flex-col md:flex-row md:space-x-5'>
                <div className='year-of-experience md:w-1/3 flex flex-col border border-white p-2 justify-center'>
                    <p className='flex justify-center text-green-600'>Years of Experience</p>
                    <p className='text-orange-600 flex justify-center'>{year}+</p>
                </div>
                <div className='project-completed md:w-1/3 flex flex-col border border-white p-2'>
                    <p className='flex justify-center text-green-600'>Completed Projects</p>
                    <p className='text-orange-600 flex justify-center'>{projects}+</p>
                </div>
                <div className='happy-clients md:w-1/3 flex flex-col border border-white p-2'>
                    <p className='flex justify-center text-green-600'>Happy Clients</p>
                    <p className='text-orange-600 flex justify-center'>{happyclient}+</p>
                </div>
            </div>
        </div>
    );
}

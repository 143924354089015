import React from 'react'
export default function Services() {
    return (
        <div className='w-full bg-black flex justify-center flex-col'>
            <h1 className='text-green-600 text-[35px] text-center'>My Services</h1>
            <div className='flex items-center'>
                <div className='services  w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4'>
                    <div className='card text-white flex w-auto flex-wrap justify-center border border-blue-700 p-4 hover:bg-sky-500 hover:scale-[103%] hover:transform transition-transform duration-500 shadow-md'>
                        <img src='/images/wordpress.png' alt='WordPress Icon' className='card-image h-48 md:h-80 lg:h-96 object-contain' />
                        <h1 className='card-title text-2xl lg:text-4xl text-center pb-2'>Expert WordPress Development</h1>
                        <div className='card-description text-lg lg:text-xl'>
                            <p className='mb-4'>Transform your online presence with my comprehensive WordPress services:</p>
                            <ul className='mb-4'>
                                <li className='mb-2'><b className='inline-block hover:scale-110 transform transition-transform duration-300 hover:text-red-600'>eCommerce:</b> Integrated stores with secure payments</li>
                                <li className='mb-2'><b className='inline-block hover:scale-110 transform transition-transform duration-300 hover:text-red-600'>Blogs & Portfolios:</b> Beautiful, easy-to-manage sites</li>
                                <li className='mb-2'><b className='inline-block hover:scale-110 transform transition-transform duration-300 hover:text-red-600'>School Management:</b> Efficient, user-friendly platforms</li>
                                <li className='mb-2'><b className='inline-block hover:scale-110 transform transition-transform duration-300 hover:text-red-600'>Travel Agency:</b> Engaging, client-attracting websites</li>
                                <li className='mb-2'><b className='inline-block hover:scale-110 transform transition-transform duration-300 hover:text-red-600'>Custom Solutions:</b> Tailored websites for any industry</li>
                            </ul>
                            <p className='text-lg'>From A to Z, I ensure your site is fast, secure, and optimized for success. Let's bring your vision to life!</p>
                        </div>
                    </div>
                    <div className='card text-white flex w-auto flex-wrap justify-center border border-blue-700 p-4 hover:bg-sky-500 hover:scale-[103%] hover:transform transition-transform duration-500 shadow-md'>
                        <img src='/images/fullstack.png' alt='React and PHP Icon' className='card-image h-48 md:h-80 lg:h-96 object-contain' />
                        <h1 className='card-title text-2xl lg:text-4xl text-center pb-2'>Full Stack Development with React and PHP</h1>
                        <div className='card-description text-lg lg:text-xl'>
                            <p className='mb-4'>Building modern web applications with both front-end and back-end expertise.</p>
                            <ul className='mb-4'>
                                <li className='mb-2'>✓ Front-end with React.js: SPA development, component-based architecture</li>
                                <li className='mb-2'>✓ Back-end with PHP: Custom web applications, database integration</li>
                                <li className='mb-2'>✓ Full stack proficiency: API integration, state management</li>
                                <li className='mb-2'>✓ Responsive and scalable solutions tailored to your business needs</li>
                            </ul>
                            <p className='text-lg'>From concept to deployment, I ensure robust and optimized solutions for your web projects.</p>
                        </div>
                    </div>
                    <div className='card text-white flex w-auto flex-wrap justify-center border border-blue-700 p-4 hover:bg-sky-500 hover:scale-[103%] hover:transform transition-transform duration-500 shadow-md'>
                        <img src='/images/telegram.png' alt='Telegram Bot Development Icon' className='card-image h-48 md:h-80 lg:h-96 object-contain' />
                        <h1 className='card-title text-2xl lg:text-4xl text-center pb-2'>Telegram Bot Development</h1>
                        <div className='card-description text-lg lg:text-xl'>
                            <p className='mb-4'>Creating interactive and efficient Telegram bots using React, PHP, and MySQL.</p>
                            <ul className='mb-4'>
                                <li className='mb-2'>✓ Front-end with React.js: Designing bot interfaces and user interactions</li>
                                <li className='mb-2'>✓ Back-end with PHP: Developing bot logic, webhook integration, data handling</li>
                                <li className='mb-2'>✓ Database management with MySQL: Storing and retrieving bot data securely</li>
                                <li className='mb-2'>✓ Full stack proficiency: API integration, real-time messaging, bot analytics</li>
                            </ul>
                            <p className='text-lg'>Crafting custom Telegram bots that enhance user engagement and automate tasks effectively.</p>
                        </div>
                    </div>
                    <div className='card text-white flex w-auto flex-wrap justify-center border border-blue-700 p-4 hover:bg-sky-500 hover:scale-[103%] hover:transform transition-transform duration-500 shadow-md'>
                        <img src='/images/react.png' alt='React Icon' className='card-image h-48 md:h-80 lg:h-96 object-contain' />
                        <h1 className='card-title text-2xl lg:text-4xl text-center pb-2'>React Development</h1>
                        <div className='card-description text-lg lg:text-xl'>
                            <p className='mb-4'>Building interactive and dynamic user interfaces with React.js.</p>
                            <ul className='mb-4'>
                                <li className='mb-2'>✓ Single-page applications (SPAs) development</li>
                                <li className='mb-2'>✓ Component-based architecture for scalability</li>
                                <li className='mb-2'>✓ State management with Redux or Context API</li>
                                <li className='mb-2'>✓ Integration of third-party APIs</li>
                            </ul>
                            <p className='text-lg'>Let's create fast, responsive, and modern web applications using React!</p>
                        </div>
                    </div>
                    <div className='card text-white flex w-auto flex-wrap justify-center border border-blue-700 p-4 hover:bg-sky-500 hover:scale-[103%] hover:transform transition-transform duration-500 shadow-md'>
                        <img src='/images/php.png' alt='PHP Icon' className='card-image h-48 md:h-80 lg:h-96 object-contain' />
                        <h1 className='card-title text-2xl lg:text-4xl text-center pb-2'>Back-end Development with PHP</h1>
                        <div className='card-description text-lg lg:text-xl'>
                            <p className='mb-4'>Building robust and scalable web applications powered by PHP programming.</p>
                            <ul className='mb-4'>
                                <li className='mb-2'>✓ Custom PHP solutions tailored to your business needs</li>
                                <li className='mb-2'>✓ Database integration (MySQL, PostgreSQL, etc.)</li>
                                <li className='mb-2'>✓ User authentication and authorization systems</li>
                                <li className='mb-2'>✓ RESTful API development and integration</li>
                            </ul>
                            <p className='text-lg'>From simple scripts to complex web applications, let's harness the power of PHP for your backend requirements.</p>
                        </div>
                    </div>
                    <div className='card text-white flex w-auto flex-wrap justify-center border border-blue-700 p-4 hover:bg-sky-500 hover:scale-[103%] hover:transform transition-transform duration-500 shadow-md'>
                        <img src='/images/html.png' alt='HTML Icon' className='card-image h-48 md:h-80 lg:h-96 object-contain' />
                        <h1 className='card-title text-2xl lg:text-4xl text-center pb-2'>Front-end Development with Plain HTML</h1>
                        <div className='card-description text-lg lg:text-xl'>
                            <p className='mb-4'>Crafting responsive and user-friendly interfaces using clean and semantic HTML5.</p>
                            <ul className='mb-4'>
                                <li className='mb-2'>✓ Pixel-perfect designs translated into HTML</li>
                                <li className='mb-2'>✓ Responsive layouts that adapt to various screen sizes</li>
                                <li className='mb-2'>✓ Accessibility best practices implemented</li>
                                <li className='mb-2'>✓ Integration with CSS and JavaScript for enhanced functionality</li>
                            </ul>
                            <p className='text-lg'>Let's create a compelling user experience with fast-loading and SEO-friendly front-end solutions.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

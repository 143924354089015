import React from "react";
import Nav from "./component/Nav";
import Home from "./component/Home";
import About from "./component/About";
import Skill from "./component/Skill";
import ProjectCount from "./component/ProjectCount";
import Services from "./component/Services";
import Contact from "./component/Contact";

function App() {
  return (
    <div className="w-full">
      <div id="nav" className="nav h-full bg-black pb-4">
        <Nav />
      </div>
      <div id="home" className="home">
        <Home />
      </div>
      <div id='projectcount w-full'>
        <ProjectCount/>
      </div>
      <div id="about" className="about w-full">
        <About/>
      </div>
      <div id="skill" className="skill w-full">
        <Skill/>
      </div>
      <div id='services' className="services w-full">
        <Services/>
      </div>
      <div className="contact">
        <Contact/>
      </div>
    </div>
  );
}

export default App;

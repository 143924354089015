import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function Contact() {
    // State to manage form data
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        query: ''
    });
    // State to manage popup visibility
    const [popup, setPopup] = useState(false);
    // State to store the message to display in the popup
    const [message, setMessage] = useState('');

    // Handler to update form data state on input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    // Effect to prevent background scrolling when popup is visible
    useEffect(() => {
        if (popup) {
            // Prevent background scrolling
            document.body.style.overflow = 'hidden';
        } else {
            // Allow normal scrolling
            document.body.style.overflow = 'auto';
        }
    }, [popup]); // Run this effect only when the 'popup' state changes

    // Handler to submit the contact form
    const contactFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://rifatxtra.xyz/backend/sendemail.php', formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.data.status === 'success') {
                setPopup(true); // Show the popup
                console.log(response.data.status);
                setMessage(response.data.message); // Set the success message
            } else {
                console.log(response.data.status);
            }
        } catch (error) {
            console.error('Failed to send the email:', error);
            setMessage('Failed to send the email.'); // Set the error message
        }
    };

    return (
        <div className='bg-[#161616] p-4 w-full'>
            <div className="contact-container w-full">
                <h1 className='text-center text-green-600 text-[35px]'>Contact Me</h1>
                {popup && (
                    <div className="popupmessage fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
                        <div className="popup-container bg-gray-700 h-[50%] w-[80%] p-6 flex flex-col justify-center items-center space-y-2">
                            <i className="fa-regular fa-circle-check text-[#2dfb04] text-[100px] lg:text-[120px]"></i>
                            <h1 className='text-[40px] lg:text-[70px] text-white'>Awesome!</h1>
                            <p className='text-[22px] lg:text-[35px] text-white'>{message}</p>
                            <button className='bg-[#2dfb04] text-white text-[25px] lg:text-[35px] px-3' onClick={() => setPopup(false)}>OK</button>
                        </div>
                    </div>
                )}
                <div className="contact-form flex flex-col md:flex-row space-x-5 w-full items-center justify-center relative">
                    <div className="contact-details text-[22px] w-full md:w-1/2 text-center">
                        <p className='text-white'>Let's Talk on Something Great together</p>
                        <div className="contact-details text-blue-300">
                            <div className="email flex flex-row space-x-2 items-center justify-center">
                                <i className="fa fa-envelope" aria-hidden="true"></i>
                                <p>business@rifatxtra.xyz</p>
                            </div>
                            <div className="telegram flex flex-row space-x-2 items-center justify-center">
                                <i className="fa-brands fa-telegram"></i>
                                <p><a href="https://t.me/rashedulrifat">@rashedulrifat</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="contact-form text-white w-full md:w-1/2 text-[25px]">
                        <form action="" className='flex flex-col w-[100%] md:w-[80%] space-y-4 p-3' onSubmit={contactFormSubmit}>
                            <div className="name flex flex-col space-y-3">
                                <label className='w-max' htmlFor="name">Name</label>
                                <input className='w-full text-black' onChange={handleChange} value={formData.name} type="text" name="name" id="name" />
                            </div>
                            <div className="email flex flex-col space-y-3">
                                <label htmlFor="email">Email</label>
                                <input className='w-full text-black' onChange={handleChange} value={formData.email} type="email" name="email" id="email" />
                            </div>
                            <div className="subject flex flex-col space-y-3">
                                <label htmlFor="subject">Subject</label>
                                <input className='w-full text-black' onChange={handleChange} value={formData.subject} type="text" name="subject" id="subject" />
                            </div>
                            <div className="message flex flex-col space-y-3">
                                <label htmlFor="query">Query</label>
                                <textarea onChange={handleChange} value={formData.query} className='text-black h-[20vh]' name="query" id="query" placeholder="Write your query here"></textarea>
                            </div>
                            <div className="submitbtn text-center">
                                <button type="submit" className='hover:bg-orange-600 p-2 rounded border border-white'>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React from 'react'

export default function Skill() {
  return (
    <div className='p-4 w-full bg-[#161616]'>
        <div className='flex flex-col w-full'>
          <h1 className='text-green-600 text-[35px] text-center'>My Skills</h1>
          <div className='container flex flex-row flex-wrap w-full justify-center items-center p-2'>
          <img src='/images/html.png' alt='Error' className='p-2 h-[100px] transform transition duration-500 hover:scale-[130%]' />
          <img src='/images/css.png' alt='Error' className='p-2 h-[100px] transform transition duration-500 hover:scale-[130%]' />
          <img src='/images/tailwind.png' alt='Error' className='p-2 h-[120px] transform transition duration-500 hover:scale-[130%]' />
          <img src='/images/js.png' alt='Error' className='p-2 h-[100px] transform transition duration-500 hover:scale-[130%]' />
          <img src='/images/php.png' alt='Error' className='p-2 h-[100px] transform transition duration-500 hover:scale-[130%]' />
          <img src='/images/react.png' alt='Error' className='p-2 h-[100px] transform transition duration-500 hover:scale-[130%]' />
          <img src='/images/wordpress.png' alt='Error' className='p-2 h-[100px] transform transition duration-500 hover:scale-[130%]' />
        </div>
        </div>
    </div>
  )
}

import React from 'react'

export default function 
() {
  return (
    <div className='w-full bg-black text-white p-4'>
        <div className='flex flex-col md:flex-row'>
            <div className='p-4 w-full md:w-6/12 flex items-center justify-center'>
                <img className='rounded-lg' src='/images/myimage1.jpg' alt='Error'/>
            </div>
            <div className='p-2 w-full md:w-6/12'>
                <h1 className='text-[30px] text-center text-green-600'>About Me</h1>
                <p className='text-[18px] p-2 text-justify lg:text-[25px]'>Hello, I am Md. Rashedul Islam, a professional full stack and WordPress developer based in the vibrant country of Bangladesh. With a deep passion for coding and a relentless drive for excellence, I have dedicated myself to crafting beautiful and functional websites that not only meet but exceed client expectations.
                </p>
                <p className='text-[18px] p-2 text-justify lg:text-[25px]'>Every day presents a new opportunity to learn and grow. I am committed to staying at the forefront of the ever-evolving tech landscape, continuously updating my skills to stay competitive.</p>
                <p className='text-[18px] p-2 text-justify lg:text-[25px]'>Whether it's a complex web application or a simple website, I approach each project with the same level of enthusiasm and attention to detail. Let's work together to bring your ideas to life and create something truly remarkable.</p>
                <p className='text-[18px] p-2 text-justify lg:text-[25px]'>Beyond technical skills, I believe in building strong, collaborative relationships with my clients. I listen carefully to your needs and work tirelessly to deliver results that align with your vision. My goal is to ensure you are fully satisfied with my service, leaving a lasting impression of professionalism and quality.</p>
            </div>
        </div>
    </div>
    
  )
}

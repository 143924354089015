import React, { useState } from 'react';
import { Link } from 'react-scroll';

export default function Nav() {
  const [showMenuItem, setshowMenuItem] = useState('bg-black transform -translate-x-full absolute');
  const [iconName,setIconName]=useState('');
  const toggleMenuIcon = (a) => {
    if (a.name === "menu-outline") {
      a.name = "close-outline";
      setshowMenuItem('bg-black bg-opacity-70 text-white transition-transform duration-500 transform translate-x-0 h-[94vh] absolute');
    } else {
      a.name = "menu-outline";
      setshowMenuItem('bg-black bg-opacity-70 text-white -translate-x-full absolute h-[94vh]');
    }
  }
  const mobileMenuCLick=()=>{
    setshowMenuItem('bg-black bg-opacity-70 text-white -translate-x-full absolute h-[94vh]');
  };
  return (
    <div className='relative'>
      <div className='mobile-menu flex flex-row md:hidden relative h-[6vh] items-center'>
        <h1 className='text-[30px] text-white p-4'>Rashedul</h1>
        <ion-icon 
          onClick={(e) => toggleMenuIcon(e.target)} 
          style={{ fontSize: '3rem', position: 'absolute', right: '0', color: 'white' }} 
          name="menu-outline">
        </ion-icon>
      </div>
      <div className={showMenuItem}>
        <ul className='mobile-navbar h-full w-[100vw] flex flex-col space-y-3 text-[30px] items-center'>
          <li><Link to='home' onClick={mobileMenuCLick} spy={true} className='cursor-pointer'>Home</Link></li>
          <li><Link to='about' onClick={mobileMenuCLick} spy={true} className='cursor-pointer'>About</Link></li>
          <li><Link to='services' onClick={mobileMenuCLick} spy={true} className='cursor-pointer'>Services</Link></li>
          <li><Link to='' onClick={mobileMenuCLick} spy={true} className='cursor-pointer'>Works</Link></li>
          <li><Link to='contact' onClick={mobileMenuCLick} spy={true} className='cursor-pointer'>Contact Me</Link></li>
        </ul>
      </div>
      <ul className='desktop-tablet-navbar hidden md:flex flex-row space-x-5 text-[30px] text-center text-white justify-center h-[6vh] items-center'>
        <li className='transform transition duration-500 hover:scale-110 hover:text-orange-600 hover:underline hover:decoration-green-800 hover:shadow hover:shadow-white'><Link to='home' spy={true} className='cursor-pointer'>Home</Link></li>
        <li className='transform transition duration-500 hover:scale-110 hover:text-orange-600 hover:underline hover:decoration-green-800 hover:shadow hover:shadow-white'><Link to='about' spy={true} className='cursor-pointer'>About</Link></li>
        <li className='transform transition duration-500 hover:scale-110 hover:text-orange-600 hover:underline hover:decoration-green-800 hover:shadow hover:shadow-white'><Link to='services' spy={true} className='cursor-pointer'>Services</Link></li>
        <li className='transform transition duration-500 hover:scale-110 hover:text-orange-600 hover:underline hover:decoration-green-800 hover:shadow hover:shadow-white'><Link to='' spy={true} className='cursor-pointer'>Works</Link></li>
        <li className='transform transition duration-500 hover:scale-110 hover:text-orange-600 hover:underline hover:decoration-green-800 hover:shadow hover:shadow-white'><Link to='contact' spy={true} className='cursor-pointer'>Contact Me</Link></li>
      </ul>
    </div>
  )
}
